import { Middleware } from 'redux';

import { Api } from '../../api/api';
import sdkMiddleware from './sdkMiddleware';

export function createMiddleware(): [Middleware] {
  const sdk = new Api();

  return [sdkMiddleware(sdk)];
}
