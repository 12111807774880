import { navigate } from 'gatsby';
import React, { createContext, useContext, useState } from 'react';

interface Context {
  visible: boolean;
  open: () => void;
  close: () => void;
}

const ModalContext = createContext<Context | null>(null);

export function ModalProvider(props: {
  children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
}): JSX.Element {
  const [visible, setVisible] = useState(false);

  function openModal() {
    setVisible(true);
  }

  function closeModal() {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete('requiresKey');
    void navigate(
      `${location.pathname}?${Array.from(queryParams.entries()).map(([k, v]) => `${k}=${v}`)}`,
      { replace: true },
    );
    setVisible(false);
  }

  const contextValue = { visible, open: openModal, close: closeModal };

  return <ModalContext.Provider value={contextValue}>{props.children}</ModalContext.Provider>;
}

export function useModal(): Context {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('Missing ModalProvider implementation');
  }

  return context;
}
