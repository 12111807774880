import React, { ComponentType, FC } from 'react';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { ModalProvider } from './contexts/filerModal';
import { persistor, store } from './lib/redux/store';

const WrapWithProvider: FC<{ element: ComponentType }> = ({ element }): JSX.Element => {
  return (
    <Provider store={store}>
      <ModalProvider>
        <PersistGate loading={null} persistor={persistor}>
          {element}
          <Toaster />
        </PersistGate>
      </ModalProvider>
    </Provider>
  );
};

export default WrapWithProvider;
