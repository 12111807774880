import { Middleware } from 'redux';

import { Api } from '../../api/api';
import { SdkAction } from '../utils/actions';

export default function sdkMiddleware(sdk: Api): Middleware {
  return ({ dispatch }) =>
    (next) =>
    (action: SdkAction): unknown => {
      if (!(action.meta && action.meta.apiRequest)) {
        return next(action);
      }

      // eslint-disable-next-line no-param-reassign
      delete action.meta.apiRequest;

      const { callback } = action.payload;

      return callback(sdk, dispatch)
        .then((response: unknown) => response)
        .catch((response: unknown) => Promise.reject(response));
    };
}
