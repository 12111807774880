import ActionTypes from '../lib/actionTypes';
import { PayloadAction } from '../lib/interfaces';
import { onFulfill, onPending, onReject } from '../lib/utils/actions';

export interface DocumentsState {
  tmpCase: string;
  tmpFile: File | null;
  totalCases: number;
  isLoading: boolean;
}

interface TotalCasesPayload {
  total_cases: number;
}

const initialState: DocumentsState = {
  tmpCase: '',
  tmpFile: null,
  isLoading: false,
  totalCases: 0,
};

export function documents(
  state: DocumentsState = initialState,
  action: PayloadAction<TotalCasesPayload> = {} as PayloadAction<TotalCasesPayload>,
): DocumentsState {
  switch (action.type) {
    case ActionTypes.SAVE_TEMPORARY_FILE:
      return { ...state, tmpFile: action.payload as unknown as File | null };

    case ActionTypes.SAVE_TEMPORARY_CASE:
      return { ...state, tmpCase: action.payload as unknown as string };

    case onPending(ActionTypes.GET_TOTAL_CASES):
      return { ...state, isLoading: true };

    case onFulfill(ActionTypes.GET_TOTAL_CASES):
      return {
        ...state,
        totalCases: action.payload!.total_cases,
        isLoading: false,
      };

    case onReject(ActionTypes.GET_TOTAL_CASES):
      return { ...state, isLoading: false };

    default:
      return state;
  }
}
