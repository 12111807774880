import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { navigate } from 'gatsby';

import { Config } from '../config';
import ActionTypes from '../lib/actionTypes';
import { store } from '../lib/redux/store';
import { onFulfill } from '../lib/utils/actions';
import { Paths } from '../paths';
import { StorageToken, api } from './api';
import { createAuthorizationHeader } from './utils';

const v1Api = axios.create({ baseURL: Config.BaseApiUrl });

type MaybeRetriedRequest = AxiosRequestConfig & { _retry?: boolean };
export type InterceptedError = AxiosError & { _shouldExit?: boolean };

async function responseInterceptor(error: AxiosError<{ msg?: string }>): Promise<AxiosResponse> {
  const originalRequest = error.config as MaybeRetriedRequest;
  const { data, status } = error.response || {};

  if (!originalRequest._retry && status === 401) {
    if (data?.msg === 'Token has expired' && !originalRequest.url?.includes('/token/refresh')) {
      // If the token has expired, we need to refresh it and retry the request
      let token;

      try {
        const { refreshToken } = await api.getToken();
        originalRequest._retry = true;
        const request = await v1Api.post('/token/refresh', undefined, {
          headers: createAuthorizationHeader({}, refreshToken),
        });
        const data = request.data;

        token = {
          refreshToken: data.refresh_token || refreshToken,
          accessToken: data.access_token,
        } as StorageToken;

        await api.setToken({ token });
      } catch (err) {
        await api.removeToken();
        store.dispatch({ type: ActionTypes.SESSION_OFF });
        store.dispatch({ type: onFulfill(ActionTypes.LOGOUT) });
        await navigate(Paths.SignIn);
        return Promise.reject(err);
      }

      originalRequest.headers = createAuthorizationHeader(
        originalRequest.headers,
        token.accessToken,
      );

      return v1Api(originalRequest);
    }

    return Promise.reject(error);
  }
  return Promise.reject(error);
}

v1Api.interceptors.response.use((response) => response, responseInterceptor);

class Client {
  static v1 = v1Api;
}

export default Client;
