import { combineReducers } from 'redux';

import { DocumentsState, documents } from './documents';
import { MeState, me } from './me';
import { SessionState, session } from './session';

const reducers = combineReducers({ me, session, documents });

export default reducers;
export interface RootState {
  me: MeState;
  session: SessionState;
  documents: DocumentsState;
}
