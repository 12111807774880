import { Action } from 'redux';

import ActionTypes from '../lib/actionTypes';

export interface SessionState {
  isAuthenticated: boolean;
}

const initialState = { isAuthenticated: false };

export function session(
  state: SessionState = initialState,
  action: Action<ActionTypes> = {} as Action<ActionTypes>,
): SessionState {
  switch (action.type) {
    case ActionTypes.SESSION_OFF:
      return {
        ...state,
        isAuthenticated: false,
      };

    case ActionTypes.SESSION_ON:
      return {
        ...state,
        isAuthenticated: true,
      };

    default:
      return state;
  }
}
