import ActionTypes from '../actionTypes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SdkAction<T = any> = {
  error: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta: Record<string, any>;
  payload: T;
  type: ActionTypes;
};

export function createAction<T>(
  type: ActionTypes,
  payload: T,
  meta: Record<string, unknown> = {},
): SdkAction<T> {
  let error = false;

  if (payload instanceof Error) {
    error = true;
  }

  return { error, meta, payload, type };
}

export function createSdkAction<T>(
  type: ActionTypes,
  payload: T,
  meta: Record<string, unknown> = {},
): SdkAction<T> {
  return createAction(type, payload, { ...meta, apiRequest: true });
}

export function onFulfill(type: ActionTypes): string {
  return `${type}_FULFILLED`;
}

export function onPending(type: ActionTypes): string {
  return `${type}_PENDING`;
}

export function onReject(type: ActionTypes): string {
  return `${type}_REJECTED`;
}
