enum ActionTypes {
  VERIFY_ACCOUNT = 'VERIFY_ACCOUNT',
  REQUEST_VERIFICATION = 'REQUEST_VERIFICATION',
  CREATE_USER = 'CREATE_USER',
  SESSION_OFF = 'SESSION_OFF',
  SESSION_ON = 'SESSION_ON',
  SESSION = 'SESSION',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  RESET_PASSWORD = 'RESET_PASSWORD',
  GET_TOTAL_CASES = 'GET_TOTAL_CASES',
  GET_SIGNED_URL = 'GET_SIGNED_URL',
  UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT',
  CITE_DOCUMENTS = 'CITE_DOCUMENTS',
  SEND_CONTACT_INFO = 'SEND_CONTACT_INFO',
  UPDATE_FILER_INFO = 'UPDATE_FILER_INFO',
  SAVE_TEMPORARY_CASE = 'SAVE_TEMPORARY_CASE',
  SAVE_TEMPORARY_FILE = 'SAVE_TEMPORARY_FILE',
  OPEN_FILER_MODAL = 'OPEN_FILER_MODAL',
  CLOSE_FILER_MODAL = 'CLOSE_FILER_MODAL',
  GET_CURRENT_USER = 'GET_CURRENT_USER',
}

export default ActionTypes;
